@import "utils";

.categoryWrapper {
  --title-gap: #{rem-calc(32)};
}

.titleWrapper {
  display: flex;
  gap: var(--title-gap);
  align-items: flex-end;

  margin-bottom: var(--title-gap);
  padding-bottom: rem-calc(12);
  border-bottom: 1px solid getColor($c-border);

  @include breakpoint(small only) {
    justify-content: space-between;
  }
}

.title {
  color: getColor($c-text-default);
}

.buttonContainer {
  display: flex;
  gap: rem-calc(8);

  @include breakpoint(small only) {
    margin-left: auto;
  }

  &:global(#{$hidden}) {
    display: none;
  }
}

.button {
  @include icon-styles {
    @include square(20);
  }
}

.achievementsSlider {
  &:global(.swiper) {
    margin-top: calc(-1 * var(--title-gap));
    margin-right: calc(-1 * var(--wrapper-padding));
    padding-top: var(--title-gap);
    padding-right: var(--wrapper-padding);
  }
}

.achievementItem {
  cursor: pointer;
}

.achievementItemBlocked {
  cursor: not-allowed;
}
